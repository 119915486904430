import { Loader } from './desktop/components';
import { DESKTOP_BASENAME } from './common/config';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const DesktopApp = React.lazy(() => import('./desktop/DesktopApp'));
const MobileApp = React.lazy(() => import('./mobile/app/MobileApp'));

// NOTES: MOBILE APP IMPACT
// 1. material-ui/core upgraded to 4.12.3 from 4.11.0
// 2. @reduxjs/toolkit upgraded to 1.8.3 from 1.5.0
// 3. Added Redux logger
// 4. Added mock util libraries fetch-mock, jest-fetch-mock, node-fetch
// 6. fade from @mui/material changed to alpha
export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Router segregation for desktop app */}
        {/* Segregation based on routing, ensures the desktop will not see the mobile view during resize and zoom in */}
        {/* also, the functionality between mobile and desktop (e.g., Authentication) is different, it is better to segragate based on the routing
        as the store is different between desktop and mobile */}
        <Route
          path={DESKTOP_BASENAME}
          element={
            <Suspense fallback={<Loader />}>
              <DesktopApp />
            </Suspense>
          }
        ></Route>
        <Route
          path={DESKTOP_BASENAME + '/*'}
          element={
            <Suspense fallback={<Loader />}>
              <DesktopApp />
            </Suspense>
          }
        ></Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <MobileApp />
            </Suspense>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};
