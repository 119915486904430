import { MapBridge } from '../../mobile/features/map/MapBridge';
import { MapBridgeDevcloud } from '../../mobile/features/map/MapBridgeDevcloud';

declare global {
  interface Window {
    REACT_APP_TARGET_ENV?: string;
    REACT_APP_API_URL?: string;
    REACT_APP_IAF_URL?: string;
    REACT_APP_ORIGINS_WHITELIST?: string[];
    REACT_APP_MAIN_LANGUAGE?: string;
    REACT_APP_ALTERNATIVE_LANGUAGE?: string;
    REACT_APP_USE_ADVISCENT?: string;
    REACT_APP_DEBUG_PRINT?: string;
    // TODO remove completely when testing is over
    mapBridge?: MapBridge | MapBridgeDevcloud;
  }
}

export const TargetEnvItems = Object.freeze({
  // we need to explicitly cast values to get the proper literal type
  devcloud: 'devcloud' as 'devcloud',
  production: 'production' as 'production',
});

export const DEBUG_PRINT = true;
// (window.REACT_APP_DEBUG_PRINT || process.env.REACT_APP_DEBUG_PRINT || 'false') === 'true';

export let USE_ADVISCENT =
  (window.REACT_APP_USE_ADVISCENT || process.env.REACT_APP_USE_ADVISCENT || 'true') === 'true';

export const TARGET_ENV =
  window.REACT_APP_TARGET_ENV || process.env.REACT_APP_TARGET_ENV || 'production';
export const API_URL = window.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
export const IAF_URL = window.REACT_APP_IAF_URL || process.env.REACT_APP_IAF_URL;
export const ORIGINS_WHITELIST =
  window.REACT_APP_ORIGINS_WHITELIST ||
  process.env.REACT_APP_ORIGINS_WHITELIST?.split(',')
    .map((s) => s.trim())
    .filter((s) => s.startsWith('http://') || s.startsWith('https://')) ||
  [];

export const EASING_BEZIER = [0.42, 0, 0.58, 1]; // animations curve
export const GENERAL_CONTACT_TEL_NUMBER = '+41 58 888 45 45';
export const TOTAL_TRIAL_HOURS = 24 * 30; // 30 days

// TODO this is intended for DevCloud only
export type Scenario =
  | '@landing-domicile:CH-terms'
  | '@landing-domicile:CH'
  | '@landing-domicile:ES'
  | '@landing-domicile:IL'
  | '@landing-domicile:US'
  | 'client-domicile:CH-ebk-hk-ino'
  | 'client-domicile:CH-ebk-new-terms'
  | 'client-domicile:CH-ebk-sg-hk-ino'
  | 'client-domicile:CH-ebk'
  | 'client-domicile:LU-eselu-r20'
  | 'expired-trial-domicile:CH'
  | 'firstTimeUser'
  | 'prospect-domicile:CH-mem'
  | 'trial-domicile:CH-mem'
  | 'forceUpdate';

export const scenarios: Scenario[] = [
  // Landing screen - no E-Services - has accepted T&C
  '@landing-domicile:CH-terms',
  // Landing screen - no E-Services - no T&C
  '@landing-domicile:CH',
  // Landing screen - no E-Services
  '@landing-domicile:ES',
  // Landing screen - no E-Services
  '@landing-domicile:IL',
  // Landing screen - no E-Services
  '@landing-domicile:US',
  // E-Banking Switzerland, Hong Kong and India
  'client-domicile:CH-ebk-hk-ino',
  // E-Banking Switzerland - hasn't accepted new T&C
  'client-domicile:CH-ebk-new-terms',
  // E-Banking Switzerland, Singapore, Hong Kong and India
  'client-domicile:CH-ebk-sg-hk-ino',
  // E-Banking Switzerland
  'client-domicile:CH-ebk',
  // E-Services Europe authorization and Switzerland R-20 service
  'client-domicile:LU-eselu-r20',
  // Expired trial
  'expired-trial-domicile:CH',
  // No E-Services, No Domicile, No T&C
  'firstTimeUser',
  // MEM E-Service as prospect
  'prospect-domicile:CH-mem',
  // MEM E-Service as trial
  'trial-domicile:CH-mem',
  // Needs to update the app
  'forceUpdate',
];

const searchParams = new URLSearchParams(window.location.search);
export const scenario: Scenario | null =
  (searchParams.get('scenario') as Scenario) || 'trial-domicile:CH-mem';

export { contactFormLinks } from './contactForms';
export { socialMediaDisclaimerLinks } from './socialMediaDisclaimers';
export * from './links';

export const DESKTOP_BASENAME = '/research';
